export default [
  {
    path: '/schools',
    name: 'Schools',
    component: () => import('@/views/schools/index.vue'),
    children: [
      {
        path: '',
        alias: ['manage'],
        name: 'ManageSchools',
        component: () => import('@/views/schools/manage_schools.vue'),
      },
      {
        path: 'action_items',
        name: 'ActionItems',
        component: () => import('@/views/schools/action_items.vue'),
      },
      {
        path: 'application',
        name: 'ApplicationForm',
        component: () => import('@/views/schools/application_form.vue'),
      },
      {
        path: 'review',
        name: 'InReview',
        component: () => import('@/views/schools/in_review.vue'),
      },
      {
        path: 'performance/:schoolId',
        name: 'SchoolPerformance',
        props: true,
        component: () => import('@/views/schools/school_performance.vue'),
      },
    ],
  },
];
