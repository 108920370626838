import { defineStore } from 'pinia';
import { ref, shallowRef, computed } from 'vue';
import { PROVINCES, STATES, validateEmail, validatePhone } from '@/common/helpers';

export const useSchoolApplicationStore = defineStore('schoolApplication', () => {
  const school = ref({
    name: null,
    street_address_1: null,
    street_address_2: null,
    city: null,
    program_type: 'ACE Service',
    state: null,
    zip_code: null,
    phone: null,
    country: 'USA',
    lms_type: 'High School',
    status: 'Pending L1 Review',
    comments: '',
    donation_only: false,
    region_id: null,
    contacts_attributes: [
      {
        name: null,
        email: null,
        phone: null,
        comments: null,
        category: null,
        is_primary: true,
        is_secondary: false,
        contactable_type: 'School',
      },
      {
        name: null,
        email: null,
        phone: null,
        comments: null,
        category: null,
        is_primary: false,
        is_secondary: true,
        contactable_type: 'School',
      },
    ],
  });

  const selectedSchoolSponsoredDealers = ref([]);
  const selectedDealers = ref([]);
  const deletedDealerContacts = ref([]);
  const sponsoredSelected = ref(false);
  const provinces = shallowRef(PROVINCES);
  const states = shallowRef(STATES);
  const validationErrors = ref({});

  const validationAddressErrors = computed(() => {
    const keys = ['streetAddress', 'state', 'city', 'programType', 'phoneNumber'];
    return keys.reduce((prev, current) => (validationErrors.value?.[current]) ? ++prev : prev, 0);
  });

  const validationContactErrors = computed(() => {
    const keys = ['name', 'category', 'email', 'emailConfirm', 'phoneNumber'];

    let errorCount = keys
      .reduce((prev, current) => {
        const errorsInContact = validationErrors.value?.contacts?.reduce((innerPrev, currentContact) => {
          return currentContact?.[current] ? ++innerPrev : innerPrev;
        }, 0) ?? 0;

        return prev + errorsInContact;
      }, 0);

    if (validationErrors.value.primaryContact) {
      errorCount++;
    }

    return errorCount;
  });

  const validationDealerContactErrors = computed(() => {
    if (!sponsoredSelected.value) {
      const keys = ['name', 'category', 'email', 'phoneNumber'];

      return keys.reduce((prev, current) => {
        const errorsInContact = validationErrors.value.dealerContacts?.reduce((innerPrev, currentContact) => {
          return currentContact?.[current] ? ++innerPrev : innerPrev;
        }, 0) ?? 0;

        return prev + errorsInContact;
      }, 0);

    }

    return null;
  });

  function validateContact(contact) {
    const errorDict = {};

    const validContactName = !!contact.name;
    const validContactCategory = !!contact.category;
    const validEmail = !!contact.email;
    const validPhoneNumber = !!contact.phone;

    if (!validContactName) {
      errorDict.name = { message: 'Field required' };
    }

    if (!validContactCategory) {
      errorDict.category = { message: 'Field required' };
    }

    // Regular Email
    if (!validEmail) {
      errorDict.email = { message: 'Field required' };
    } else {
      // if it is valid, then let's validate email too.
      if (!validateEmail(contact.email)) {
        errorDict.email = { message: 'Not a valid email' };
      }
    }

    // if there's an email validation in part of it, let's validate it
    if (contact.emailConfirm && contact.emailConfirm !== contact.email) {
      errorDict.emailConfirm = { message: 'Not the same email' };
    }

    if (!validPhoneNumber) {
      errorDict.phoneNumber = { message: 'Field required' };
    } else {
      if (!validatePhone(contact.phone)) {
        errorDict.phoneNumber = { message: 'Not a valid phone number' };
      }
    }

    return errorDict;
  }

  function validateSchoolAddress() {
    // The !! checks for truthy value, essentially checking if they've been filled in.
    const validStreetAddress = !!school.value.street_address_1;
    const validCity = !!school.value.city;
    const validState = !!school.value.state;
    const validProgramType = !!school.value.program_type;
    const validZipCode = !!school.value.zip_code;
    const validPhoneNumber = !!school.value.phone;

    // Reset its value
    validationErrors.value = {};

    if (!validStreetAddress) {
      validationErrors.value.streetAddress = {
        message: 'Field is required',
      };
    }

    if (!validCity) {
      validationErrors.value.city = {
        message: 'Field is required',
      };
    }

    if (!validState) {
      validationErrors.value.state = {
        message: 'Field is required',
      };
    }

    if (!validProgramType) {
      validationErrors.value.programType = {
        message: 'Field is required',
      };
    }

    if (!validZipCode) {
      validationErrors.value.zipCode = {
        message: 'Field is required',
      }
    }

    if (!validPhoneNumber) {
      validationErrors.value.phoneNumber = {
        message: 'Field is required',
      };
    }
  }

  function validateDealerContacts() {
    validationErrors.value.dealerContacts = selectedSchoolSponsoredDealers.value
      .flatMap(dealer => dealer?.contacts_attributes ?? [])
      .map(contact => validateContact(contact));
  }

  function validateSchoolContacts() {
    validationErrors.value.contacts = school.value.contacts_attributes
      .map((contact) => validateContact(contact));

    // This checks if there's at least 1 primary contact
    const hasOnePrimaryContact = school.value.contacts_attributes.some(contact => contact.is_primary);

    if (!hasOnePrimaryContact) {
      validationErrors.value.primaryContact = { message: 'Primary Contact selection required' };
    }
  }

  // attempts to validate the school on the frontend side.
  function validateSchool(addProgramType = false) {
    validateSchoolAddress();
    validateSchoolContacts();

    if (school.value.parent_id) {
      return validationAddressErrors.value === 0;

    } else if (!addProgramType) {
      validateDealerContacts();

      return validationAddressErrors.value === 0 && validationContactErrors.value === 0 && validationDealerContactErrors.value === 0;
    }

    return validationAddressErrors.value === 0 && validationContactErrors.value === 0;
  }

  function resetSchool() {
    school.value = {
      name: null,
      street_address_1: null,
      street_address_2: null,
      city: null,
      program_type: 'ACE Service',
      state: null,
      zip_code: null,
      phone: null,
      country: 'USA',
      lms_type: 'High School',
      status: 'Pending L1 Review',
      comments: '',
      donation_only: false,
      region_id: null,
      contacts_attributes: [
        {
          name: null,
          email: null,
          phone: null,
          comments: null,
          category: null,
          is_primary: true,
          is_secondary: false,
          contactable_type: 'School',
        },
        {
          name: null,
          email: null,
          phone: null,
          comments: null,
          category: null,
          is_primary: false,
          is_secondary: true,
          contactable_type: 'School',
        },
      ],
    };
    selectedSchoolSponsoredDealers.value = [];
    selectedDealers.value = [];
    deletedDealerContacts.value = [];
    sponsoredSelected.value = false;
    validationErrors.value = {};
  }

  function setSchool(newSchool) {
    school.value = newSchool;
  }

  return {
    school,
    provinces,
    states,
    sponsoredSelected,
    selectedDealers,
    selectedSchoolSponsoredDealers,
    deletedDealerContacts,
    validationAddressErrors,
    validationContactErrors,
    validationDealerContactErrors,
    validationErrors,
    validateSchool,
    resetSchool,
    setSchool,
  };
})
