export default [
  {
    path: '/reports',
    name: 'Reports',
    component: () => import('@/views/reporting/index.vue'),
    children: [
      {
        path: '',
        alias: ['summary'],
        name: 'ReportsSummary',
        component: () => import('@/views/reporting/summary.vue'),
      },
      {
        path: 'custom',
        name: 'ReportsCustom',
        component: () => import('@/views/reporting/custom.vue'),
      },
      {
        path: 'quarterly',
        name: 'ReportsQuarterly',
        component: () => import('@/views/reporting/quarterly.vue'),
      },
      {
        path: 'hsc-details',
        name: 'ReportsHscDetails',
        component: () => import('@/views/reporting/hsc-details.vue'),
      },
      {
        path: 'school_performance_builder',
        name: 'SchoolPerformanceBuilder',
        component: () => import('@/views/reporting/school_performance_builder.vue'),
      },
      {
        path: 'dealer_performance_builder',
        name: 'DealerPerformanceBuilder',
        component: () => import('@/views/reporting/dealer_performance_builder.vue'),
      },
    ],
  },
];
