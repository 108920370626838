export default [
  {
    path: '/dealerships',
    name: 'Dealerships',
    component: () => import('@/views/dealerships/index.vue'),
    children: [
      {
        path: '',
        alias: ['participating'],
        name: 'ManageDealerships',
        component: () => import('@/views/dealerships/manage_dealerships.vue'),
      },
      {
        path: 'add',
        name: 'AddADealership',
        component: () => import('@/views/dealerships/add_dealership.vue'),
      },
      {
        path: 'performance/:dealerId',
        name: 'DealerPerformance',
        component: () => import('@/views/dealerships/dealer_performance.vue'),
        props: true,
      },
    ],
  },
];
