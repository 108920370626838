import { createRouter, createWebHistory } from 'vue-router';
import { isTokenValid, destroyToken, parseJwt, saveToken } from '@/common/services/jwt.service';
import * as Routes from './routes';
import { useSchoolApplicationStore } from '../stores/school-application';
import { notify } from "@kyvg/vue3-notification";

const routes = [
  // ---------------------- Sessions ----------------------
  ...Routes.SessionRoutes,
  {
    path: '/',
    name: 'Home',
    component: () => import('@/views/home/Home.vue'),
    meta: { requiresAuth: true },
    redirect: (to) => {
      const jwt = parseJwt();

      if (jwt?.role === 'Titling') {
        return { name: 'UnitInventory' };
      }
      
      return { name: 'ManageSchools' };
    },
    children: [
      ...Routes.AdminRoutes,
      ...Routes.HomeRoutes,
      ...Routes.SchoolRoutes,
      ...Routes.DealershipRoutes,
      ...Routes.ReportingRoutes,
      ...Routes.DonationRoutes,
      {
        path: '/global_alert_history',
        name: 'GlobalAlertHistory',
        component: () => import('@/views/uncategorized/global_alert_history.vue'),
      },
    ],
  },
];

const checkAuth = async(to, from) => {
  // Let's check for the route being authorized as we need to ensure for login
  if (['/authorize', '/adfs/callback'].includes(to.path)) {
    if (to.query?.status_code === '403' || to.query?.status_code === '406') {
      destroyToken();
      notify({
        title: 'Please Request Access',
        text: 'Please request access to the ACE Administrator Portal from an Admin before attempting to log in.',
        type: 'warn',
        width: 350,
        duration: 10000,
      });
      return { name: 'Login' };
    } else {
      const { token } = to.query;
      saveToken(token);
      return { path: '/schools/manage' };
    }
  }

  // Check for authenticated routes
  const jwt = parseJwt();

  if (to.matched.some(record => record.meta.requiresAuth)) {
    // If token is invalid, return to login
    if (!isTokenValid()) {
      // Destroy token as it's no longer in use
      destroyToken();
      return { name: 'Login' };
    }

    // if there's a match to require admin, check if token is an admin
    if (to.matched.some(record => record.meta.requiresAdmin) && jwt.role !== 'Super Admin') {
      return { name: 'Home' };
    }
  }
};

const router = createRouter({
  history: createWebHistory(),
  linkActiveClass: 'active',
  routes,
});

// Ensure we checked auth before each page load.
router.beforeEach(checkAuth);

router.afterEach((to, from) => {
  if (to.path.includes('/school')) {
    const schoolAppStore = useSchoolApplicationStore();
    schoolAppStore.resetSchool();
  }
});

export default router;
