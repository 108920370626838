export default [
  {
    path: '/donations',
    name: 'Donations',
    component: () => import('@/views/donations/index.vue'),
    children: [
      {
        path: '',
        alias: ['unit-inventory'],
        name: 'UnitInventory',
        component: () => import('@/views/donations/unit-inventory.vue'),
      },
      {
        path: 'unit-inventory/:donationId',
        name: 'UnitInventoryDetail',
        component: () => import('@/views/donations/unit-inventory-detail.vue'),
      },
      {
        path: 'action-items',
        name: 'DonationActionItems',
        component: () => import('@/views/donations/action-items.vue'),
      },
      {
        path: 'review',
        name: 'DonationsInReview',
        component: () => import('@/views/donations/in-review.vue'),
      },
    ],
  },
];
